import * as React from "react";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import TextPage, { TextBlock } from '../components/text-page';
import commonMessageValues from '../templates/formatjs-message-values';

const PrepaidCards = (): JSX.Element => {
  const intl = useIntl();
  const messages = intl.messages;
  const textBlocks: TextBlock[] = [{
    header: `${messages.prepaid_cards}`,
    message: <FormattedMessage id="message_prepaid_cards" values={commonMessageValues}/>
  }];

  return (<TextPage locale={intl.locale} title={`${messages.prepaid_cards}`} textBlocks={textBlocks}/>);
};

export default PrepaidCards;